import basicStatus from '@/@fake-db/data/basicStatus.json'
import crmLine from '@/api/CRM-line/crmLine'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { removeComma } from '@/views/reports/useExcel'
import { mdiFileExcelOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUserOnline() {
  const searchtext = ref('')
  const statusList = ref(basicStatus.data)
  const online_status_id = ref('')
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'online_id',
      width: 50,
    },
    {
      text: i18n.t('firstname_lastname'),
      value: 'online_fullname',
    },
    { text: i18n.t('id_card'), value: 'online_idcard' },
    { text: i18n.t('gender'), value: 'online_gender' },
    { text: i18n.t('tel'), value: 'online_tel' },
    {
      text: i18n.t('email'),
      value: 'online_email',
      width: 120,
    },
    { text: i18n.t('birthday'), value: 'online_birthdate' },
    { text: i18n.t('status'), value: 'online_status_name', align: 'center' },
  ])
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const loading = ref(false)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const exportLoading = ref(false)
  const XLSX = require('xlsx')

  const fetchDataTable = () => {
    loading.value = true
    crmLine
      .userOnlineList({
        searchtext: searchtext.value,
        online_status_id: online_status_id.value,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      })
      .then(res => {
        const { data, count, segment, count_of_page } = res
        dataTableList.value = data
        totalPage.value = count_of_page
        totalDataTableList.value = count
        segmentId.value = segment
        loading.value = false
      })
  }

  const exportExcel = () => {
    exportLoading.value = true
    crmLine
      .userOnlineList({
        searchtext: '',
        online_status_id: '',
        active_page: '1',
        per_page: '-1',
      })
      .then(async res => {
        const mapData = res.data.map((item, index) => {
          item.number = index + 1

          return item
        })
        const dataExport = await removeComma(JSON.parse(JSON.stringify(mapData)))
        const fileName = `${i18n.t('UserOnline')}.xlsx`

        // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
        /*  รายงานวิเคราะห์รายการตรวจ */

        const Heading = [
          [`${i18n.t('ReportOnline')}`],
          [
            '#',
            `${i18n.t('customer')}`,
            `${i18n.t('idcard')}`,
            `${i18n.t('gender')}`,
            `${i18n.t('tel')}`,
            `${i18n.t('email')}`,
            `${i18n.t('birthday')}`,
            `${i18n.t('status')}`,
          ],
        ]

        const ws = XLSX.utils.aoa_to_sheet(Heading)
        XLSX.utils.sheet_add_json(ws, dataExport, {
          header: [
            'number',
            'online_fullname',
            'online_idcard',
            'online_gender',
            'online_tel',
            'online_email',
            'online_birthdate',
            'online_status_name',
          ],
          skipHeader: true,
          origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }]
        const wscols = [
          { wch: 6 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ]
        ws['!merges'] = merge
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('ReportOnline')}`)

        /* พิมร์ files */
        XLSX.writeFile(wb, fileName)

        setTimeout(() => {
          exportLoading.value = false
        }, 1500)
      })
  }

  watch([searchtext, online_status_id, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    searchtext,
    statusList,
    online_status_id,
    options,
    footer,
    dataTableList,
    totalDataTableList,
    loading,
    totalPage,
    columns,
    exportLoading,
    segmentId,
    fetchDataTable,
    exportExcel,
    icons: {
      mdiFileExcelOutline,
    },
  }
}
